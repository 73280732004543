<app-admin-comp-wrapper>
    <div class="container-fluid relative px-3">
        <div class="layout-specing">
            <!-- Start Content -->
            <div class="md:flex justify-between items-center">
                <div>
                    <h5 class="font-medium text-base text-slate-400">Member Management</h5>
                    <h5 class="font-semibold text-2xl mb-1">Commission</h5>
                </div>
                <!-- <span class="text-slate-400">My balance: <span class="font-semibold text-emerald-600">$ 45,578.032</span></span> -->
            </div>

            <div class="bg-slate-900 rounded-lg p-3 flex flex-col min-[1750px]:flex-row gap-2 w-full items-center mt-5">
                <div class="flex gap-2 w-full items-center">
                    <div class="mx-1">
                        <label for="agent-id">Select Games</label>
                        <select type="text" name="search-criteria" class="input-custom-1 w-full">
                            <option value="member-listing">SPORT</option>
                            <option value="member-listing">SLOT</option>
                            <option value="member-listing">RNG</option>
                            <option value="member-listing">P2P (1GPOKER)</option>
                            <option value="member-listing">Lottery (Special 4D)</option>
                            <option value="member-listing">LOTTERY 2 (TOGEL)</option>
                            <option value="member-listing">LOTTERY (TOGEL)</option>
                            <option value="member-listing">LOTTERY (NUMBER)</option>
                            <option value="member-listing">LIVE CASINO (SBO)</option>
                            <option value="member-listing">LIVE CASINO</option>
                            <option value="member-listing">HKB POKER</option>
                            <option value="member-listing">HKB LOTTERY</option>
                            <option value="member-listing">HKB LIVE</option>
                            <option value="member-listing">FISHING</option>
                            <option value="member-listing">COCKFIGHT</option>
                        </select>
                    </div>
                    <div class="mx-1">
                        <input checked id="checked-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                        <label for="checked-checkbox" class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Apply to all Downline</label>
                    </div>
                    <div class="mx-1">
                        <input checked id="checked-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                        <label for="checked-checkbox" class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Apply to all Games</label>
                    </div>
                </div>

            <div class="bg-slate-900 rounded-lg p-3 flex flex-col md:flex-row gap-2 w-full items-center mt-5">
                <div class="flex gap-2 md:w-[29rem] w-full items-center">
                    <div class="w-full">
                        <label for="ticket-id">Search</label>
                        <input type="text" name="ticket-id" class="input-custom-1 w-full"
                        placeholder="">
                    </div>
                </div>
      
                <button mat-button class="bg-yellow-600 rounded-lg text-white md:w-72 py-6 w-full">Search</button>
            </div>

            </div>

            <div class="overflow-x-auto mt-3 rounded-lg bg-gray-700">
                <table class="table-striped w-full text-xs text-left text-gray-700 border-none">
                    <thead class="bg-slate-900 text-gray-200">
                        <tr>
                            <th scope="col" class="px-6 py-3 text-center">#</th>
                            <th scope="col" class="px-6 py-3 text-center">Agent ID</th>
                            <th scope="col" class="px-6 py-3 text-center">Account Name</th>
                            <th scope="col" class="px-6 py-3 text-center">
                                <input checked id="checked-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                            </th>
                            <th scope="col" class="px-6 py-3 text-center">Comm Group A</th>
                            <th scope="col" class="px-6 py-3 text-center">Comm Group B</th>
                            <th scope="col" class="px-6 py-3 text-center">Last Login Time</th>
                        </tr>
                    </thead>
                    <tbody class="bg-slate-800">
                        @for (commission of commissionData; track commission.agentId) {
                            <tr>
                                <td class="px-2 py-2 text-center"></td>
                                <td class="px-2 py-2 text-center">{{commission.agentId}}</td>
                                <td class="px-2 py-2 text-center">{{commission.accountName}}</td>
                                <td class="px-2 py-2 text-center">
                                    <input checked id="checked-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                                </td>
                                <td class="px-2 py-2 text-center">{{commission.commGroupA}}</td>
                                <td class="px-2 py-2 text-center">{{commission.commGroupB}}</td>
                                <td class="px-2 py-2 text-center">{{commission.lastLoginTime}}</td>
                            </tr>
                        }
                       
                    </tbody>
                </table>
            </div>



        </div>
    </div><!--end container-->
</app-admin-comp-wrapper>
