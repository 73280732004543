import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { RouterLink } from '@angular/router';
import { SimplebarAngularModule } from 'simplebar-angular';
import {NgClickOutsideDirective} from 'ng-click-outside2';

import { ethers } from 'ethers';

@Component({
  selector: 'app-admin-comp-navbar',
  templateUrl: './admin-comp-navbar.component.html',
  styleUrl: './admin-comp-navbar.component.scss',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    SimplebarAngularModule,
    NgClickOutsideDirective
  ],
})
export class AdminCompNavbarComponent {
  show: boolean = true;

  notification:boolean = false

  onClickedOutside(e: Event) {
    e.preventDefault();
    this.notification = false
  }

  user:boolean = false

  onClickedOutside2(e: Event) {
    e.preventDefault();
    this.user = false
  }

  @Output() showChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  toggleShow(e:any) {
    e.preventDefault();
    this.show = !this.show;
    this.showChange.emit(this.show);  // Emit the `show` value
  }

  public account: string | null = null;

  async connectMetaMask() {
    try {
      if (!(window as any).ethereum) {
        alert('MetaMask is not installed. Please install it to continue.');
        return;
      }
      // Correct provider creation in ethers v6
      const provider = new ethers.BrowserProvider((window as any).ethereum); // No more `Web3Provider`
      const accounts = await provider.send('eth_requestAccounts', []);
      this.account = accounts[0];
      console.log('Connected account:', this.account);
    } catch (error) {
      console.error('Failed to connect MetaMask:', error);
    }
  }

 
}
