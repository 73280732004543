import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Router, RouterEvent, RouterLink } from '@angular/router';
import { SimplebarAngularModule } from 'simplebar-angular';

@Component({
  selector: 'app-admin-comp-sidebar',
  templateUrl: './admin-comp-sidebar.component.html',
  styleUrl: './admin-comp-sidebar.component.scss',
  standalone: true,
  imports : [
    CommonModule,
    RouterLink,
    SimplebarAngularModule
  ]
})
export class AdminCompSidebarComponent {
  menu:any
  subManu:any

  constructor(private router:Router){
    this.menu = this.router.url
    window.scrollTo(0,0)
  }

  openMenu(e:any,item:any){
    e.preventDefault();
    
    if(this.subManu === item){
      this.subManu = ''
    }else{
      this.subManu = item
    }
  }

}
