import { Component } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { SimplebarAngularModule } from 'simplebar-angular';
import { AdminCompWrapperComponent } from '../../components/admin-comp-wrapper/admin-comp-wrapper.component';
import { RouterLink } from '@angular/router';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-admin-pages-report-runningreport',
  templateUrl: './admin-pages-report-runningreport.component.html',
  styleUrl: './admin-pages-report-runningreport.component.scss',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    AdminCompWrapperComponent,
    SimplebarAngularModule,
    MatIcon
  ]
})
export class AdminPagesReportRunningreportComponent {

  date = new Date();

  runningReportList = [
    {
      agentID : "LOGAA",
      username : "logaa",
      activeMember : 15842,
      newRegAccPerDep : "3636/3092",
      totalNewDep : 278367608.00,
      totalTurnover : 28374794049.90,
      totalWinlose : -676141838.42,
      totalDeposit : 2894398789.00,
      totalWithdrawal: 2384839625.00,
      totalAdjDep : 655632.00,
      totalAdjWithdraw : 488905.00,
      totalPromotion : 66385239.30,
      totalCashback : 43321383.74,
      totalCashRebate : 10956883.34,
      totalReffComm : 26711363.06,
      totalBonusCredit : 0.00
    }
  ]
  

}
