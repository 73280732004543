import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { AdminCompWrapperComponent } from '../../components/admin-comp-wrapper/admin-comp-wrapper.component';
import { SimplebarAngularModule } from 'simplebar-angular';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-admin-pages-tools-masteripwhitelist',
  templateUrl: './admin-pages-tools-masteripwhitelist.component.html',
  styleUrl: './admin-pages-tools-masteripwhitelist.component.scss',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    AdminCompWrapperComponent,
    SimplebarAngularModule,
    MatIcon
  ]
})
export class AdminPagesToolsMasteripwhitelistComponent {
  masterIPList = [
    {
      agentID: "LOG",
      username: 'log_leader@baru',
      ipWhitelistList: ["127.0.0.1", "123.84.672.36"],
    },
    {
      agentID: "LAG",
      username: 'lag_leader@baru',
      ipWhitelistList: ["127.0.0.1"]
    },
  ]
}
