<app-admin-comp-wrapper>
    <div class="container-fluid relative px-3">
        <div class="layout-specing">
            <!-- Start Content -->
            <div class="md:flex justify-between items-center">
                <div>
                    <h5 class="font-medium text-base text-slate-400">Tools</h5>
                    <h5 class="font-semibold text-2xl mb-1">Game Settings</h5>
                </div>
                <!-- <span class="text-slate-400">My balance: <span class="font-semibold text-emerald-600">$ 45,578.032</span></span> -->
            </div>


            <div class="overflow-x-auto mt-3 rounded-lg bg-gray-700">
                <table class="table-striped w-full text-xs text-left text-gray-700 border-none">
                    <thead class="bg-slate-900 text-gray-200">
                        <tr>
                            <th scope="col" class="px-6 py-3 text-center">#</th>
                            <th scope="col" class="px-6 py-3 text-center">Game</th>
                            <th scope="col" class="px-6 py-3 text-center">
                                <input checked id="checked-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                                <label for="checked-checkbox" class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Maintenance All</label>
                            </th>
                            <th scope="col" class="px-6 py-3 text-center">
                                <input id="default-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                                <label for="checked-checkbox" class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Hide All</label>
                            </th>
                            <th scope="col" class="px-6 py-3 text-center">
                                <select type="text" name="rows" class="input-custom-1 w-full">
                                    <option value="50">nav.downline_agent_edit</option>
                                </select> 
                            </th>

                        </tr>
                    </thead>
                    <tbody class="bg-slate-800">
                        @for (gameMaintenance of gameMaintenanceList; track gameMaintenance.game) {
                            <tr>
                                <td class="px-2 py-2 text-center"></td>
                                <td class="px-2 py-2 text-center">{{gameMaintenance.game}}</td>
                                <td class="px-2 py-2 text-center">
                                    <input id="default-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                                </td>
                                <td class="px-2 py-2 text-center">
                                    <input id="default-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                                </td>
                                <td class="px-2 py-2 text-center">-</td>

                            </tr>
                        }
                       
                    </tbody>
                </table>
            </div>



        </div>
    </div><!--end container-->
</app-admin-comp-wrapper>
