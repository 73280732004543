import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';
import { AdminCompWrapperComponent } from '../../components/admin-comp-wrapper/admin-comp-wrapper.component';
import { SimplebarAngularComponent, SimplebarAngularModule } from 'simplebar-angular';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-admin-pages-membermanage-acclist',
  templateUrl: './admin-pages-membermanage-acclist.component.html',
  styleUrl: './admin-pages-membermanage-acclist.component.scss',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    AdminCompWrapperComponent,
    SimplebarAngularModule,
    MatIcon
  ]
})
export class AdminPagesMembermanageAcclistComponent {
  constructor() { }

  statementLogs= [
    {
      walletType : "Game Wallet",
      source : "PRAGMATICPLAY",
      trackID : "13265134141123134",
      amount : "-10000000",
      balanceBefore : "19000000",
      balanceAfter : "9000000",
      dateTime : "2024-10-20 17:20:13"
    },
    {
      walletType : "Game Wallet",
      source : "PRAGMATICPLAY",
      trackID : "1326513235234235",
      amount : "-10000000",
      balanceBefore : "38000000",
      balanceAfter : "18000000",
      dateTime : "2024-10-20 15:20:20"
    }
  ]

  accounts = [
  {
    agentID : "LOGAAC212X",
    username :"just069",
    ownRefId:"LOGAAC212X",
    uplineRefId:"",
    status:"On",
    suspend:"No",
    mailingSubscription:"No",
    bankRefundMethod:"BRI",
    balance:"9700",
    createdDate:"2024-10-20 15:34:32",
    firstDepositDate:"2024-10-20 15:34:32",
    lastDepositDate:"2024-10-20 15:34:32",
    lastLoginDate:"2024-10-20 15:29:50",
    lastLoginIP:"182.3.102.143",
  },
  {
    agentID : "LOGAAAA07JP",
    username :"maramoe",
    ownRefId:"LOGAAAA07JP",
    uplineRefId:"",
    status:"On",
    suspend:"No",
    mailingSubscription:"No",
    bankRefundMethod:"GOPAY",
    balance:"270",
    createdDate:"2024-10-20 15:05:33",
    firstDepositDate:"2024-10-20 15:09:10",
    lastDepositDate:"2024-10-20 15:09:10",
    lastLoginDate:"2024-10-20 15:09:10",
    lastLoginIP:"182.1.181.143",
  },
  ]
}
