import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { AdminCompWrapperComponent } from '../../components/admin-comp-wrapper/admin-comp-wrapper.component';
import { SimplebarAngularModule } from 'simplebar-angular';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-admin-pages-tools-gamemanagement',
  templateUrl: './admin-pages-tools-gamemanagement.component.html',
  styleUrl: './admin-pages-tools-gamemanagement.component.scss',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    AdminCompWrapperComponent,
    SimplebarAngularModule,
    MatIcon
  ]
})
export class AdminPagesToolsGamemanagementComponent {
  gameMaintenanceList = [
    {
      game : "SLOTS: HABANERO",
    },
    {
      game : "SLOTS: TOPTRENDGAMING",
    },
    {
      game : "SLOTS: JOKER",
    },
    {
      game : "SLOTS: MICROGAMING",
    },
    {
      game : "SLOTS: SPADEGAMING",
    },
    {
      game : "SLOTS: PRAGMATICPLAY",
    },
    {
      game : "SLOTS: PLAYNGO",
    },
    {
      game : "SLOTS: SKYWIND",
    },
    {
      game : "SLOTS: CQ9",
    },
    {
      game : "SLOTS: YGG",
    },
    {
      game : "SLOTS: BOOMING",
    },
    {
      game : "SLOTS: PGSOFT",
    },
    {
      game : "SLOTS: PLAYSTAR",
    },
    {
      game : "SLOTS: RED TIGER",
    },
    {
      game : "SLOTS: BOOONGO",
    },
    {
      game : "SLOTS: EVOPLAY",
    },
    {
      game : "SLOTS: NOLIMITCITY",
    },
    {
      game : "SLOTS: MANCALA GAMING",
    },
    {
      game : "SLOTS: EA GAMING",
    },
    {
      game : "SLOTS: NETENT",
    },
  ]
}
