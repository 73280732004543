<app-admin-comp-wrapper>
    <div class="container-fluid relative px-3">
        <div class="layout-specing">
            <!-- Start Content -->
            <div class="md:flex justify-between items-center">
                <div>
                    <h5 class="font-medium text-base text-slate-400">Tools</h5>
                    <h5 class="font-semibold text-2xl mb-1">Member IP Track</h5>
                </div>
                <!-- <span class="text-slate-400">My balance: <span class="font-semibold text-emerald-600">$ 45,578.032</span></span> -->
            </div>

            <div class="bg-slate-900 rounded-lg p-3 flex flex-col min-[1750px]:flex-row gap-2 w-full items-center mt-5">

                <div class="flex gap-2 w-full items-center">
                    <div class="mx-1">
                        <label for="date-start">Date Start</label>
                        <input type="date" name="date-start" class="input-custom-1 w-full"
                        placeholder="Masukan kata kunci">
                    </div>
                    <div class="mx-1">
                        <label for="date-end">Date End</label>
                        <input type="date" name="date-end" class="input-custom-1 w-full"
                        placeholder="Masukan kata kunci">
                    </div>
                </div>

                <div class="flex gap-2 w-full items-center">
                    <div class="mx-1">
                        <label for="rows">Search Criteria</label>
                        <select type="text" name="rows" class="input-custom-1 w-full">
                            <option value="50">Agent ID</option>
                            <option value="50">Username</option>
                            <option value="50">IP Address</option>
                        </select>
                    </div>
                    <div class="mx-1">
                        <label for="agent-id">Search Value</label>
                        <input type="text" name="agent-id" class="input-custom-1 w-full"
                        placeholder="">
                    </div>

                </div>
                <div class="flex flex-col mt-3">
                    <button mat-button class="bg-yellow-600 rounded-lg text-white w-72 py-1 mb-2 w-50">Search</button>
                </div>
            </div>
            <div class="overflow-x-auto mt-3 rounded-lg bg-gray-700">
                <table class="table-striped w-full text-xs text-left text-gray-700 border-none">
                    <thead class="bg-slate-900 text-gray-200">
                        <tr>
                            <th scope="col" class="px-6 py-3 text-center">#</th>
                            <th scope="col" class="px-6 py-3 text-center">Member</th>
                            <th scope="col" class="px-6 py-3 text-center">IP Address</th>
                            <th scope="col" class="px-6 py-3 text-center">Device</th>
                            <th scope="col" class="px-6 py-3 text-center">Browser / Operating System</th>
                            <th scope="col" class="px-6 py-3 text-center">Country</th>
                            <th scope="col" class="px-6 py-3 text-center">Login Data</th>
                            <th scope="col" class="px-6 py-3 text-center">View Map</th>

                        </tr>
                    </thead>
                    <tbody class="bg-slate-800">
                        @for (ipTrack of ipTrackData; track ipTrack.member) {
                            <tr>
                                <td class="px-2 py-2 text-center"></td>
                                <td class="px-2 py-2 text-center">{{ipTrack.member}}</td>
                                <td class="px-2 py-2 text-center">{{ipTrack.ipAddress}}</td>
                                <td class="px-2 py-2 text-center">{{ipTrack.device}}</td>
                                <td class="px-2 py-2 text-center">{{ipTrack.browserOperatingSystem}}</td>
                                <td class="px-2 py-2 text-center">{{ipTrack.country}}</td>
                                <td class="px-2 py-2 text-center">{{ipTrack.loginDate}}</td>
                                <td class="px-2 py-2 text-center">
                                    <button mat-button class="bg-yellow-600 rounded-lg text-white px-2">View</button>
                                </td>
                            </tr>
                        }
                       
                    </tbody>
                </table>
            </div>



        </div>
    </div><!--end container-->
</app-admin-comp-wrapper>
