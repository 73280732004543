import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { AdminCompWrapperComponent } from '../../components/admin-comp-wrapper/admin-comp-wrapper.component';
import { SimplebarAngularModule } from 'simplebar-angular';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-admin-pages-tools-ipwhitelist',
  templateUrl: './admin-pages-tools-ipwhitelist.component.html',
  styleUrl: './admin-pages-tools-ipwhitelist.component.scss',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    AdminCompWrapperComponent,
    SimplebarAngularModule,
    MatIcon
  ]
})
export class AdminPagesToolsIpwhitelistComponent {
  IPList = [
    {
      agentID : "LOG",
      username : 'log_leader@baru',
      ipWhitelistList : ["127.0.0.1"]
    },
    {
      agentID : "LAG",
      username : 'lag_leader@baru',
      ipWhitelistList : ["127.0.0.1"]
    },
  ]
}
