import { Component } from '@angular/core';
import NftData from '../../../../data/nft.json'
import CreatorData from '../../../../data/creator.json'
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';
import { AdminCompWrapperComponent } from '../../components/admin-comp-wrapper/admin-comp-wrapper.component';
import { SimplebarAngularComponent, SimplebarAngularModule } from 'simplebar-angular';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-admin-pages-main',
  templateUrl: './admin-pages-main.component.html',
  styleUrl: './admin-pages-main.component.scss',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    AdminCompWrapperComponent,
    SimplebarAngularModule,
    MatIcon
  ]
})
export class AdminPagesMainComponent {
  constructor() { }

  creatorData = CreatorData
  nftData = NftData.slice(0,6)

  ngAfterViewInit(): void {
    this.loadTradingViewWidget();
  }

  loadTradingViewWidget() {
    const script = document.createElement('script');
    script.src = 'https://s3.tradingview.com/tv.js';
    script.async = true;
    script.onload = () => {
      new (window as any).TradingView.widget({
        width: '100%',
        height: 450,
        symbol: 'BITSTAMP:ETHUSD',
        interval: 'D',
        timezone: 'Etc/UTC',
        theme: 'Dark', // Change your chart background color as per your comfort (Light or Dark)
        style: '1',
        locale: 'en',
        toolbar_bg: '#000',
        enable_publishing: false,
        withdateranges: true,
        hide_side_toolbar: false,
        allow_symbol_change: true,
        show_popup_button: true,
        popup_width: '1000',
        popup_height: '650',
        container_id: 'tradingview_123'
      });
    };

    document.body.appendChild(script);
  }
}
