import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { AdminCompWrapperComponent } from '../../components/admin-comp-wrapper/admin-comp-wrapper.component';
import { SimplebarAngularModule } from 'simplebar-angular';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-admin-pages-tools-memberip',
  templateUrl: './admin-pages-tools-memberip.component.html',
  styleUrl: './admin-pages-tools-memberip.component.scss',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    AdminCompWrapperComponent,
    SimplebarAngularModule,
    MatIcon
  ]
})
export class AdminPagesToolsMemberipComponent {

  ipTrackData = [
    {
      member : "royal69srnd",
      ipAddress : "114.10.66.81",
      device : "Mobile Browser",
      browserOperatingSystem : "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/129.0.0.0 Mobile Safari/537.36",
      country : "ID",
      loginDate : "2024-10-20 17:01:44"
    },
    {
      member : "solikin9",
      ipAddress : "182.2.50.72",
      device : "Mobile Browser",
      browserOperatingSystem : "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/129.0.0.0 Mobile Safari/537.36",
      country : "ID",
      loginDate : "2024-10-20 16:59:38"
    },
    {
      member : "wawankmp",
      ipAddress : "182.2.79.62",
      device : "Mobile Browser",
      browserOperatingSystem : "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/129.0.0.0 Mobile Safari/537.36",
      country : "ID",
      loginDate : "2024-10-20 16:47:40"
    },
  ]

}
