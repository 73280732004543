import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { AdminCompSidebarComponent } from '../admin-comp-sidebar/admin-comp-sidebar.component';
import { AdminCompNavbarComponent } from '../admin-comp-navbar/admin-comp-navbar.component';
import * as feather from 'feather-icons'

@Component({
  selector: 'app-admin-comp-wrapper',
  templateUrl: './admin-comp-wrapper.component.html',
  styleUrl: './admin-comp-wrapper.component.scss',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    AdminCompSidebarComponent,
    AdminCompNavbarComponent
],
})
export class AdminCompWrapperComponent {
  year:any

  show: boolean = true;

  updateShow(newShowValue: boolean) {
    this.show = newShowValue; // Update the `show` value in the parent component
  }

  ngOnInit(): void {
    this.year = new Date().getFullYear();
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    feather.replace()
  }
}
