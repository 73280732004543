import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';
import { AdminCompWrapperComponent } from '../../components/admin-comp-wrapper/admin-comp-wrapper.component';
import { SimplebarAngularComponent, SimplebarAngularModule } from 'simplebar-angular';
import { MatIcon } from '@angular/material/icon';
import NftData from '../../../../data/nft.json'
import CreatorData from '../../../../data/creator.json'
import { Wallet } from 'ethers';

@Component({
  selector: 'app-admin-pages-membermanage-statementlog',
  templateUrl: './admin-pages-membermanage-statementlog.component.html',
  styleUrl: './admin-pages-membermanage-statementlog.component.scss',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    AdminCompWrapperComponent,
    SimplebarAngularModule,
    MatIcon
  ]
})
export class AdminPagesMembermanageStatementlogComponent{

  constructor() { }

  statementLogs= [
    {
      walletType : "Game Wallet",
      source : "PRAGMATICPLAY",
      trackID : "13265134141123134",
      amount : "-10000000",
      balanceBefore : "19000000",
      balanceAfter : "9000000",
      dateTime : "2024-10-20 17:20:13"
    },
    {
      walletType : "Game Wallet",
      source : "PRAGMATICPLAY",
      trackID : "1326513235234235",
      amount : "-10000000",
      balanceBefore : "38000000",
      balanceAfter : "18000000",
      dateTime : "2024-10-20 15:20:20"
    }
  ]
}
