import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';
import { AdminCompWrapperComponent } from '../../components/admin-comp-wrapper/admin-comp-wrapper.component';
import { SimplebarAngularComponent, SimplebarAngularModule } from 'simplebar-angular';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-admin-pages-report-winlosebygames',
  templateUrl: './admin-pages-report-winlosebygames.component.html',
  styleUrl: './admin-pages-report-winlosebygames.component.scss',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    AdminCompWrapperComponent,
    SimplebarAngularModule,
    MatIcon
  ]
})
export class AdminPagesReportWinlosebygamesComponent {
  constructor() { }

  winLoseByGames = [
    {
    gameProvider : "Pragmatic Play Slots",
    totalActiveMembers : 48,
    turnOver : 45824975.00,
    member : {
      win : -684725.00,
      comm : 0.00,
      total : -684725.00
    },
    agent : {
      win : 547780.00,
      comm : 0.00,
      progJP: 0.00,
      total : 547780.00
    },
    mAgent : {
      win : 61625.25,
      comm : 0.00,
      progJP: 0.00,
      total : 61625.25
    },
    SMAgent : {
      win : 0.00,
      comm : 0.00,
      progJP: 0.00,
      total : 0.00
    },
    progJP : 0.0,
    companyTotal : 75319.75
  },
  {
    gameProvider : "PGSOFT Slots",
    totalActiveMembers : 48,
    turnOver : 45824975.00,
    member : {
      win : -684725.00,
      comm : 0.00,
      total : -684725.00
    },
    agent : {
      win : 547780.00,
      comm : 0.00,
      progJP: 0.00,
      total : 547780.00
    },
    mAgent : {
      win : 61625.25,
      comm : 0.00,
      progJP: 0.00,
      total : 61625.25
    },
    SMAgent : {
      win : 0.00,
      comm : 0.00,
      progJP: 0.00,
      total : 0.00
    },
    progJP : 0.0,
    companyTotal : 75319.75
  },
]

}
