import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminPagesComponent } from './admin-pages.component';
import { AdminDashboardComponent } from './components/admin-dashboard/admin-dashboard.component';
import { AdminPagesMainComponent } from './pages/admin-pages-main/admin-pages-main.component';
import { AdminPagesMembermanageStatementlogComponent } from './pages/admin-pages-membermanage-statementlog/admin-pages-membermanage-statementlog.component';
import { AdminPagesMembermanageAcclistComponent } from './pages/admin-pages-membermanage-acclist/admin-pages-membermanage-acclist.component';
import { AdminPagesReportWinlosebygamesComponent } from './pages/admin-pages-report-winlosebygames/admin-pages-report-winlosebygames.component';
import { AdminPagesMembermanageNewmaComponent } from './pages/admin-pages-membermanage-newma/admin-pages-membermanage-newma.component';
import { AdminPagesMembermanageCommisionComponent } from './pages/admin-pages-membermanage-commision/admin-pages-membermanage-commision.component';
import { AdminPagesToolsMemberipComponent } from './pages/admin-pages-tools-memberip/admin-pages-tools-memberip.component';
import { AdminPagesToolsMasteripwhitelistComponent } from './pages/admin-pages-tools-masteripwhitelist/admin-pages-tools-masteripwhitelist.component';
import { AdminPagesToolsGamemanagementComponent } from './pages/admin-pages-tools-gamemanagement/admin-pages-tools-gamemanagement.component';
import { AdminPagesToolsIpwhitelistComponent } from './pages/admin-pages-tools-ipwhitelist/admin-pages-tools-ipwhitelist.component';
import { AdminPagesReportDailyreportComponent } from './pages/admin-pages-report-dailyreport/admin-pages-report-dailyreport.component';
import { AdminPagesReportRunningreportComponent } from './pages/admin-pages-report-runningreport/admin-pages-report-runningreport.component';
import { AdminPagesReportStatementComponent } from './pages/admin-pages-report-statement/admin-pages-report-statement.component';
import { AdminPagesReportDailytotalgamesreportComponent } from './pages/admin-pages-report-dailytotalgamesreport/admin-pages-report-dailytotalgamesreport.component';

const routes: Routes = [{
  path :'',
  component:AdminPagesComponent,
  canActivate : [],
  canActivateChild: [],
  children : [
    {
      path : 'admin/dashboard',
      component : AdminPagesMainComponent
    },
    {
      path : 'admin/member-manangement/statement-log',
      component : AdminPagesMembermanageStatementlogComponent
    },
    {
      path : 'admin/member-manangement/account-list',
      component : AdminPagesMembermanageAcclistComponent
    },
    {
      path : 'admin/member-manangement/new-ma',
      component : AdminPagesMembermanageNewmaComponent
    },
    {
      path : 'admin/member-manangement/commission',
      component : AdminPagesMembermanageCommisionComponent
    },
    {
      path : 'admin/report/winlose-report-by-game',
      component : AdminPagesReportWinlosebygamesComponent
    },
    {
      path : 'admin/report/daily-report',
      component : AdminPagesReportDailyreportComponent
    },
    {
      path : 'admin/report/running-report',
      component : AdminPagesReportRunningreportComponent
    },
    {
      path : 'admin/report/statement',
      component : AdminPagesReportStatementComponent
    },
    {
      path : 'admin/report/daily-total-games-report',
      component : AdminPagesReportDailytotalgamesreportComponent
    },
    {
      path : 'admin/tools/member-ip-track',
      component : AdminPagesToolsMemberipComponent
    },
    {
      path : 'admin/tools/master-ip-whitelist',
      component : AdminPagesToolsMasteripwhitelistComponent
    },
    {
      path : 'admin/tools/game-settings',
      component : AdminPagesToolsGamemanagementComponent
    },
    {
      path : 'admin/tools/ip-whitelist',
      component : AdminPagesToolsIpwhitelistComponent
    },
  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminPagesRoutingModule { }
