<app-admin-comp-wrapper>
    <div class="container-fluid relative px-3">
        <div class="layout-specing">
            <!-- Start Content -->
            <div class="md:flex justify-between items-center">
                <div>
                    <h5 class="font-medium text-base text-slate-400">Member Management</h5>
                    <h5 class="font-semibold text-2xl mb-1">New MA</h5>
                </div>
                <!-- <span class="text-slate-400">My balance: <span class="font-semibold text-emerald-600">$ 45,578.032</span></span> -->
            </div>

            <!-- <div class="bg-slate-900 rounded-lg p-3 flex flex-col md:flex-row gap-2 w-full items-center mt-5">
                <div class="flex gap-2 md:w-[29rem] w-full items-center">
                    <div class="w-full">
                        <label for="agent-id">Agent ID</label>
                        <input type="text" name="agent-id" class="input-custom-1 w-full"
                        placeholder="">
                    </div>
                    <div class="w-full">
                        <label for="date">Date</label>
                        <input type="date" name="date" class="input-custom-1 w-full"
                        placeholder="Masukan kata kunci">
                    </div>
                </div>
                <div class="flex gap-2 md:w-[29rem] w-full items-center">
                    <div class="w-full">
                        <label for="ticket-id">Ticket ID</label>
                        <input type="text" name="ticket-id" class="input-custom-1 w-full"
                        placeholder="">
                    </div>
                    <div class="w-full">
                        <label for="rows">Rows</label>
                        <input type="number" name="rows" class="input-custom-1 w-full"
                        placeholder="">
                    </div>
                </div>
      
                <button mat-button class="bg-yellow-600 rounded-lg text-white md:w-72 py-6 w-full">Search</button>
            </div>

            <div class="pt-7">
                <button mat-button class="bg-slate-900 rounded-lg text-white px-2 py-1 mx-1">Copy</button>
                <button mat-button class="bg-slate-900 rounded-lg text-white px-2 py-1 mx-1">CSV</button>
                <button mat-button class="bg-slate-900 rounded-lg text-white px-2 py-1 mx-1">Excel</button>
                <button mat-button class="bg-slate-900 rounded-lg text-white px-2 py-1 mx-1">PDF</button>
                <button mat-button class="bg-slate-900 rounded-lg text-white px-2 py-1 mx-1">Print</button>
            </div> -->

            <!-- Table Dream -->
            <div class="overflow-x-auto mt-3 rounded-lg bg-gray-700">
                <table class="table-striped w-full text-xs text-left text-gray-700 border-none">
                    <thead class="bg-slate-900 text-gray-200">
                        <tr>
                            <th colspan=5 scope="col" class="px-6 py-3">User Information</th>
                        </tr>
                    </thead>
                    <tbody class="bg-slate-800">
                        <tr>
                            <td colspan="1" class="px-2 py-2 text-center">Agent ID</td>
                            <td colspan="4" class="px-2 py-2 text-center">
                                <div>
                                    LOG
                                </div>
                                <div>
                                    <select type="text" name="categories" class="input-custom-1 py-1 my-1">
                                        <option value="all-games">A</option>
                                    </select>
                                </div>
                                <div>
                                    <select type="text" name="categories" class="input-custom-1 py-1">
                                        <option value="all-games">B</option>
                                    </select>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="1" class="px-2 py-2 text-center">Password</td>
                            <td colspan="1" class="px-2 py-2 text-center">
                                <input id="pass" type="password" class="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-3" placeholder="">
                            </td>
                            <td colspan="1" class="px-2 py-2 text-center">Confirm Password</td>
                            <td colspan="1" class="px-2 py-2 text-center">
                                <input id="confirmPass" type="password" class="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-3" placeholder="">
                            </td>
                        </tr>
                        <tr>
                            <td colspan="1" class="px-2 py-2 text-center">Currency</td>
                            <td colspan="1" class="px-2 py-2 text-center">
                                <select type="text" name="categories" class="input-custom-1 py-1">
                                    <option value="all-games">IDR</option>
                                </select>
                            </td>
                            <td colspan="1" class="px-2 py-2 text-center">Email Address</td>
                            <td colspan="1" class="px-2 py-2 text-center">
                                <input id="LoginUsername" type="text" class="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-3" placeholder="Masukkan Username">
                            </td>
                        </tr>
                        <tr>
                            <td colspan="1" class="px-2 py-2 text-center">Account Name</td>
                            <td colspan="1" class="px-2 py-2 text-center">
                                <input id="LoginUsername" type="text" class="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-3" placeholder="Masukkan Username">
                            </td>
                            <td colspan="1" class="px-2 py-2 text-center">Mobile</td>
                            <td colspan="1" class="px-2 py-2 text-center">
                                <input id="LoginUsername" type="text" class="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-3" placeholder="Masukkan Username">
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- End Content -->

            <div class="overflow-x-auto mt-3 rounded-lg bg-gray-700">
                <table class="table-striped w-full text-xs text-left text-gray-700 border-none">
                    <thead class="bg-slate-900 text-gray-200">
                        <tr>
                            <th colspan=5 scope="col" class="px-6 py-3">Credit Setting</th>
                        </tr>
                    </thead>
                    <tbody class="bg-slate-800">
                        <tr>
                            <td colspan="1" class="px-2 py-2 text-center">Max Win</td>
                            <td colspan="4" class="px-2 py-2 text-center">
                                    <select type="text" name="categories" class="input-custom-1 py-1 my-1">
                                        <option value="all-games">Unlimited</option>
                                        <option value="all-games">100</option>
                                    </select>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="overflow-x-auto mt-3 rounded-lg bg-gray-700">
                <table class="table-striped w-full text-xs text-left text-gray-700 border-none">
                    <thead class="bg-slate-900 text-gray-200">
                        <tr>
                            <th colspan=5 scope="col" class="px-6 py-3">Commision Given</th>
                        </tr>
                    </thead>
                    <tbody class="bg-slate-800">
                        <tr>
                            <td colspan="1" class="px-2 py-2 text-center">Sport</td>
                            <td colspan="4" class="px-2 py-2 text-center">
                                    <select type="text" name="categories" class="input-custom-1 py-1 my-1">
                                        <option value="all-games">100 %</option>
                                        <option value="all-games">50%</option>
                                    </select>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="1" class="px-2 py-2 text-center">Slot</td>
                            <td colspan="4" class="px-2 py-2 text-center">
                                    <select type="text" name="categories" class="input-custom-1 py-1 my-1">
                                        <option value="all-games">100 %</option>
                                        <option value="all-games">50%</option>
                                    </select>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="1" class="px-2 py-2 text-center">RNG</td>
                            <td colspan="4" class="px-2 py-2 text-center">
                                    <select type="text" name="categories" class="input-custom-1 py-1 my-1">
                                        <option value="all-games">100 %</option>
                                        <option value="all-games">50%</option>
                                    </select>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="1" class="px-2 py-2 text-center">Lottery (Special 4D)</td>
                            <td colspan="4" class="px-2 py-2 text-center">
                                    <select type="text" name="categories" class="input-custom-1 py-1 my-1">
                                        <option value="all-games">100 %</option>
                                        <option value="all-games">50%</option>
                                    </select>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="1" class="px-2 py-2 text-center">Lottery 2 (Togel)</td>
                            <td colspan="4" class="px-2 py-2 text-center">
                                    <select type="text" name="categories" class="input-custom-1 py-1 my-1">
                                        <option value="all-games">100 %</option>
                                        <option value="all-games">50%</option>
                                    </select>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="1" class="px-2 py-2 text-center">Lottery (Togel)</td>
                            <td colspan="4" class="px-2 py-2 text-center">
                                    <select type="text" name="categories" class="input-custom-1 py-1 my-1">
                                        <option value="all-games">100 %</option>
                                        <option value="all-games">50%</option>
                                    </select>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="1" class="px-2 py-2 text-center">Lottery (Number)</td>
                            <td colspan="4" class="px-2 py-2 text-center">
                                    <select type="text" name="categories" class="input-custom-1 py-1 my-1">
                                        <option value="all-games">100 %</option>
                                        <option value="all-games">50%</option>
                                    </select>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="1" class="px-2 py-2 text-center">Live Casino (SBO)</td>
                            <td colspan="4" class="px-2 py-2 text-center">
                                    <select type="text" name="categories" class="input-custom-1 py-1 my-1">
                                        <option value="all-games">100 %</option>
                                        <option value="all-games">50%</option>
                                    </select>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="1" class="px-2 py-2 text-center">Live Casino</td>
                            <td colspan="4" class="px-2 py-2 text-center">
                                    <select type="text" name="categories" class="input-custom-1 py-1 my-1">
                                        <option value="all-games">100 %</option>
                                        <option value="all-games">50%</option>
                                    </select>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="1" class="px-2 py-2 text-center">HKB Poker</td>
                            <td colspan="4" class="px-2 py-2 text-center">
                                    <select type="text" name="categories" class="input-custom-1 py-1 my-1">
                                        <option value="all-games">100 %</option>
                                        <option value="all-games">50%</option>
                                    </select>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="1" class="px-2 py-2 text-center">HKB Lottery</td>
                            <td colspan="4" class="px-2 py-2 text-center">
                                    <select type="text" name="categories" class="input-custom-1 py-1 my-1">
                                        <option value="all-games">100 %</option>
                                        <option value="all-games">50%</option>
                                    </select>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="1" class="px-2 py-2 text-center">HKB Live</td>
                            <td colspan="4" class="px-2 py-2 text-center">
                                    <select type="text" name="categories" class="input-custom-1 py-1 my-1">
                                        <option value="all-games">100 %</option>
                                        <option value="all-games">50%</option>
                                    </select>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="1" class="px-2 py-2 text-center">Fishing</td>
                            <td colspan="4" class="px-2 py-2 text-center">
                                    <select type="text" name="categories" class="input-custom-1 py-1 my-1">
                                        <option value="all-games">100 %</option>
                                        <option value="all-games">50%</option>
                                    </select>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="1" class="px-2 py-2 text-center">Cockfight</td>
                            <td colspan="4" class="px-2 py-2 text-center">
                                    <select type="text" name="categories" class="input-custom-1 py-1 my-1">
                                        <option value="all-games">100 %</option>
                                        <option value="all-games">50%</option>
                                    </select>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="1" class="px-2 py-2 text-center">P2P (1GPOKER)</td>
                            <td colspan="4" class="px-2 py-2 text-center">
                                    <select type="text" name="categories" class="input-custom-1 py-1 my-1">
                                        <option value="all-games">100 %</option>
                                        <option value="all-games">50%</option>
                                    </select>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="1" class="px-2 py-2 text-center">Group A</td>
                            <td colspan="4" class="px-2 py-2 text-center">
                                    <select type="text" name="categories" class="input-custom-1 py-1 my-1">
                                        <option value="all-games">100 %</option>
                                        <option value="all-games">50%</option>
                                    </select>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="1" class="px-2 py-2 text-center">Group B</td>
                            <td colspan="4" class="px-2 py-2 text-center">
                                    <select type="text" name="categories" class="input-custom-1 py-1 my-1">
                                        <option value="all-games">100 %</option>
                                        <option value="all-games">50%</option>
                                    </select>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="overflow-x-auto mt-3 rounded-lg bg-gray-700">
                <table class="table-striped w-full text-xs text-left text-gray-700 border-none">
                    <thead class="bg-slate-900 text-gray-200">
                        <tr>
                            <th colspan=5 scope="col" class="px-6 py-3">Position Taking</th>
                        </tr>
                    </thead>
                    <tbody class="bg-slate-800">
                        <tr>
                            <td colspan="1" class="px-2 py-2 text-center"></td>
                            <td colspan="1" class="px-2 py-2 text-center">Min PT</td>
                            <td colspan="1" class="px-2 py-2 text-center">Force PT</td>
                            <td colspan="1" class="px-2 py-2 text-center">Total PT</td>
                            <td colspan="1" class="px-2 py-2 text-center">Take Remaining</td>
                        </tr>

                        <tr>
                            <td colspan="1" class="px-2 py-2 text-center">Sport</td>
                            <td colspan="1" class="px-2 py-2 text-center">
                                <select type="text" name="categories" class="input-custom-1 py-1 my-1">
                                    <option value="all-games">100 %</option>
                                    <option value="all-games">50%</option>
                                </select>       
                            </td>
                            <td colspan="1" class="px-2 py-2 text-center">
                                <select type="text" name="categories" class="input-custom-1 py-1 my-1">
                                    <option value="all-games">100 %</option>
                                    <option value="all-games">50%</option>
                                </select>
                            </td>
                            <td colspan="1" class="px-2 py-2 text-center">
                                <select type="text" name="categories" class="input-custom-1 py-1 my-1">
                                    <option value="all-games">100 %</option>
                                    <option value="all-games">50%</option>
                                </select>
                            </td>
                            <td colspan="1" class="px-2 py-2 text-center">
                                <select type="text" name="categories" class="input-custom-1 py-1 my-1">
                                    <option value="all-games">Yes</option>
                                    <option value="all-games">No</option>
                                </select>
                            </td>
                        </tr>

                        <tr>
                            <td colspan="1" class="px-2 py-2 text-center">Sport</td>
                            <td colspan="1" class="px-2 py-2 text-center">
                                <select type="text" name="categories" class="input-custom-1 py-1 my-1">
                                    <option value="all-games">100 %</option>
                                    <option value="all-games">50%</option>
                                </select>       
                            </td>
                            <td colspan="1" class="px-2 py-2 text-center">
                                <select type="text" name="categories" class="input-custom-1 py-1 my-1">
                                    <option value="all-games">100 %</option>
                                    <option value="all-games">50%</option>
                                </select>
                            </td>
                            <td colspan="1" class="px-2 py-2 text-center">
                                <select type="text" name="categories" class="input-custom-1 py-1 my-1">
                                    <option value="all-games">100 %</option>
                                    <option value="all-games">50%</option>
                                </select>
                            </td>
                            <td colspan="1" class="px-2 py-2 text-center">
                                <select type="text" name="categories" class="input-custom-1 py-1 my-1">
                                    <option value="all-games">Yes</option>
                                    <option value="all-games">No</option>
                                </select>
                            </td>
                        </tr>

                        <tr>
                            <td colspan="1" class="px-2 py-2 text-center">Slot</td>
                            <td colspan="1" class="px-2 py-2 text-center">
                                <select type="text" name="categories" class="input-custom-1 py-1 my-1">
                                    <option value="all-games">100 %</option>
                                    <option value="all-games">50%</option>
                                </select>       
                            </td>
                            <td colspan="1" class="px-2 py-2 text-center">
                                <select type="text" name="categories" class="input-custom-1 py-1 my-1">
                                    <option value="all-games">100 %</option>
                                    <option value="all-games">50%</option>
                                </select>
                            </td>
                            <td colspan="1" class="px-2 py-2 text-center">
                                <select type="text" name="categories" class="input-custom-1 py-1 my-1">
                                    <option value="all-games">100 %</option>
                                    <option value="all-games">50%</option>
                                </select>
                            </td>
                            <td colspan="1" class="px-2 py-2 text-center">
                                <select type="text" name="categories" class="input-custom-1 py-1 my-1">
                                    <option value="all-games">Yes</option>
                                    <option value="all-games">No</option>
                                </select>
                            </td>
                        </tr>

                        <tr>
                            <td colspan="1" class="px-2 py-2 text-center">RNG</td>
                            <td colspan="1" class="px-2 py-2 text-center">
                                <select type="text" name="categories" class="input-custom-1 py-1 my-1">
                                    <option value="all-games">100 %</option>
                                    <option value="all-games">50%</option>
                                </select>       
                            </td>
                            <td colspan="1" class="px-2 py-2 text-center">
                                <select type="text" name="categories" class="input-custom-1 py-1 my-1">
                                    <option value="all-games">100 %</option>
                                    <option value="all-games">50%</option>
                                </select>
                            </td>
                            <td colspan="1" class="px-2 py-2 text-center">
                                <select type="text" name="categories" class="input-custom-1 py-1 my-1">
                                    <option value="all-games">100 %</option>
                                    <option value="all-games">50%</option>
                                </select>
                            </td>
                            <td colspan="1" class="px-2 py-2 text-center">
                                <select type="text" name="categories" class="input-custom-1 py-1 my-1">
                                    <option value="all-games">Yes</option>
                                    <option value="all-games">No</option>
                                </select>
                            </td>
                        </tr>

                        <tr>
                            <td colspan="1" class="px-2 py-2 text-center">Lottery (Special 4D)</td>
                            <td colspan="1" class="px-2 py-2 text-center">
                                <select type="text" name="categories" class="input-custom-1 py-1 my-1">
                                    <option value="all-games">100 %</option>
                                    <option value="all-games">50%</option>
                                </select>       
                            </td>
                            <td colspan="1" class="px-2 py-2 text-center">
                                <select type="text" name="categories" class="input-custom-1 py-1 my-1">
                                    <option value="all-games">100 %</option>
                                    <option value="all-games">50%</option>
                                </select>
                            </td>
                            <td colspan="1" class="px-2 py-2 text-center">
                                <select type="text" name="categories" class="input-custom-1 py-1 my-1">
                                    <option value="all-games">100 %</option>
                                    <option value="all-games">50%</option>
                                </select>
                            </td>
                            <td colspan="1" class="px-2 py-2 text-center">
                                <select type="text" name="categories" class="input-custom-1 py-1 my-1">
                                    <option value="all-games">Yes</option>
                                    <option value="all-games">No</option>
                                </select>
                            </td>
                        </tr>

                        <tr>
                            <td colspan="1" class="px-2 py-2 text-center">Lottery 2 (Togel)</td>
                            <td colspan="1" class="px-2 py-2 text-center">
                                <select type="text" name="categories" class="input-custom-1 py-1 my-1">
                                    <option value="all-games">100 %</option>
                                    <option value="all-games">50%</option>
                                </select>       
                            </td>
                            <td colspan="1" class="px-2 py-2 text-center">
                                <select type="text" name="categories" class="input-custom-1 py-1 my-1">
                                    <option value="all-games">100 %</option>
                                    <option value="all-games">50%</option>
                                </select>
                            </td>
                            <td colspan="1" class="px-2 py-2 text-center">
                                <select type="text" name="categories" class="input-custom-1 py-1 my-1">
                                    <option value="all-games">100 %</option>
                                    <option value="all-games">50%</option>
                                </select>
                            </td>
                            <td colspan="1" class="px-2 py-2 text-center">
                                <select type="text" name="categories" class="input-custom-1 py-1 my-1">
                                    <option value="all-games">Yes</option>
                                    <option value="all-games">No</option>
                                </select>
                            </td>
                        </tr>

                        <tr>
                            <td colspan="1" class="px-2 py-2 text-center">Lottery (Togel)</td>
                            <td colspan="1" class="px-2 py-2 text-center">
                                <select type="text" name="categories" class="input-custom-1 py-1 my-1">
                                    <option value="all-games">100 %</option>
                                    <option value="all-games">50%</option>
                                </select>       
                            </td>
                            <td colspan="1" class="px-2 py-2 text-center">
                                <select type="text" name="categories" class="input-custom-1 py-1 my-1">
                                    <option value="all-games">100 %</option>
                                    <option value="all-games">50%</option>
                                </select>
                            </td>
                            <td colspan="1" class="px-2 py-2 text-center">
                                <select type="text" name="categories" class="input-custom-1 py-1 my-1">
                                    <option value="all-games">100 %</option>
                                    <option value="all-games">50%</option>
                                </select>
                            </td>
                            <td colspan="1" class="px-2 py-2 text-center">
                                <select type="text" name="categories" class="input-custom-1 py-1 my-1">
                                    <option value="all-games">Yes</option>
                                    <option value="all-games">No</option>
                                </select>
                            </td>
                        </tr>

                        <tr>
                            <td colspan="1" class="px-2 py-2 text-center">Lottery (Number)</td>
                            <td colspan="1" class="px-2 py-2 text-center">
                                <select type="text" name="categories" class="input-custom-1 py-1 my-1">
                                    <option value="all-games">100 %</option>
                                    <option value="all-games">50%</option>
                                </select>       
                            </td>
                            <td colspan="1" class="px-2 py-2 text-center">
                                <select type="text" name="categories" class="input-custom-1 py-1 my-1">
                                    <option value="all-games">100 %</option>
                                    <option value="all-games">50%</option>
                                </select>
                            </td>
                            <td colspan="1" class="px-2 py-2 text-center">
                                <select type="text" name="categories" class="input-custom-1 py-1 my-1">
                                    <option value="all-games">100 %</option>
                                    <option value="all-games">50%</option>
                                </select>
                            </td>
                            <td colspan="1" class="px-2 py-2 text-center">
                                <select type="text" name="categories" class="input-custom-1 py-1 my-1">
                                    <option value="all-games">Yes</option>
                                    <option value="all-games">No</option>
                                </select>
                            </td>
                        </tr>

                        <tr>
                            <td colspan="1" class="px-2 py-2 text-center">Live Casino (SBO)</td>
                            <td colspan="1" class="px-2 py-2 text-center">
                                <select type="text" name="categories" class="input-custom-1 py-1 my-1">
                                    <option value="all-games">100 %</option>
                                    <option value="all-games">50%</option>
                                </select>       
                            </td>
                            <td colspan="1" class="px-2 py-2 text-center">
                                <select type="text" name="categories" class="input-custom-1 py-1 my-1">
                                    <option value="all-games">100 %</option>
                                    <option value="all-games">50%</option>
                                </select>
                            </td>
                            <td colspan="1" class="px-2 py-2 text-center">
                                <select type="text" name="categories" class="input-custom-1 py-1 my-1">
                                    <option value="all-games">100 %</option>
                                    <option value="all-games">50%</option>
                                </select>
                            </td>
                            <td colspan="1" class="px-2 py-2 text-center">
                                <select type="text" name="categories" class="input-custom-1 py-1 my-1">
                                    <option value="all-games">Yes</option>
                                    <option value="all-games">No</option>
                                </select>
                            </td>
                        </tr>

                        <tr>
                            <td colspan="1" class="px-2 py-2 text-center">Live Casino</td>
                            <td colspan="1" class="px-2 py-2 text-center">
                                <select type="text" name="categories" class="input-custom-1 py-1 my-1">
                                    <option value="all-games">100 %</option>
                                    <option value="all-games">50%</option>
                                </select>       
                            </td>
                            <td colspan="1" class="px-2 py-2 text-center">
                                <select type="text" name="categories" class="input-custom-1 py-1 my-1">
                                    <option value="all-games">100 %</option>
                                    <option value="all-games">50%</option>
                                </select>
                            </td>
                            <td colspan="1" class="px-2 py-2 text-center">
                                <select type="text" name="categories" class="input-custom-1 py-1 my-1">
                                    <option value="all-games">100 %</option>
                                    <option value="all-games">50%</option>
                                </select>
                            </td>
                            <td colspan="1" class="px-2 py-2 text-center">
                                <select type="text" name="categories" class="input-custom-1 py-1 my-1">
                                    <option value="all-games">Yes</option>
                                    <option value="all-games">No</option>
                                </select>
                            </td>
                        </tr>

                        <tr>
                            <td colspan="1" class="px-2 py-2 text-center">HKB Poker</td>
                            <td colspan="1" class="px-2 py-2 text-center">
                                <select type="text" name="categories" class="input-custom-1 py-1 my-1">
                                    <option value="all-games">100 %</option>
                                    <option value="all-games">50%</option>
                                </select>       
                            </td>
                            <td colspan="1" class="px-2 py-2 text-center">
                                <select type="text" name="categories" class="input-custom-1 py-1 my-1">
                                    <option value="all-games">100 %</option>
                                    <option value="all-games">50%</option>
                                </select>
                            </td>
                            <td colspan="1" class="px-2 py-2 text-center">
                                <select type="text" name="categories" class="input-custom-1 py-1 my-1">
                                    <option value="all-games">100 %</option>
                                    <option value="all-games">50%</option>
                                </select>
                            </td>
                            <td colspan="1" class="px-2 py-2 text-center">
                                <select type="text" name="categories" class="input-custom-1 py-1 my-1">
                                    <option value="all-games">Yes</option>
                                    <option value="all-games">No</option>
                                </select>
                            </td>
                        </tr>

                        <tr>
                            <td colspan="1" class="px-2 py-2 text-center">HKB Lottery</td>
                            <td colspan="1" class="px-2 py-2 text-center">
                                <select type="text" name="categories" class="input-custom-1 py-1 my-1">
                                    <option value="all-games">100 %</option>
                                    <option value="all-games">50%</option>
                                </select>       
                            </td>
                            <td colspan="1" class="px-2 py-2 text-center">
                                <select type="text" name="categories" class="input-custom-1 py-1 my-1">
                                    <option value="all-games">100 %</option>
                                    <option value="all-games">50%</option>
                                </select>
                            </td>
                            <td colspan="1" class="px-2 py-2 text-center">
                                <select type="text" name="categories" class="input-custom-1 py-1 my-1">
                                    <option value="all-games">100 %</option>
                                    <option value="all-games">50%</option>
                                </select>
                            </td>
                            <td colspan="1" class="px-2 py-2 text-center">
                                <select type="text" name="categories" class="input-custom-1 py-1 my-1">
                                    <option value="all-games">Yes</option>
                                    <option value="all-games">No</option>
                                </select>
                            </td>
                        </tr>

                        <tr>
                            <td colspan="1" class="px-2 py-2 text-center">HKB Live</td>
                            <td colspan="1" class="px-2 py-2 text-center">
                                <select type="text" name="categories" class="input-custom-1 py-1 my-1">
                                    <option value="all-games">100 %</option>
                                    <option value="all-games">50%</option>
                                </select>       
                            </td>
                            <td colspan="1" class="px-2 py-2 text-center">
                                <select type="text" name="categories" class="input-custom-1 py-1 my-1">
                                    <option value="all-games">100 %</option>
                                    <option value="all-games">50%</option>
                                </select>
                            </td>
                            <td colspan="1" class="px-2 py-2 text-center">
                                <select type="text" name="categories" class="input-custom-1 py-1 my-1">
                                    <option value="all-games">100 %</option>
                                    <option value="all-games">50%</option>
                                </select>
                            </td>
                            <td colspan="1" class="px-2 py-2 text-center">
                                <select type="text" name="categories" class="input-custom-1 py-1 my-1">
                                    <option value="all-games">Yes</option>
                                    <option value="all-games">No</option>
                                </select>
                            </td>
                        </tr>

                        <tr>
                            <td colspan="1" class="px-2 py-2 text-center">Fishing</td>
                            <td colspan="1" class="px-2 py-2 text-center">
                                <select type="text" name="categories" class="input-custom-1 py-1 my-1">
                                    <option value="all-games">100 %</option>
                                    <option value="all-games">50%</option>
                                </select>       
                            </td>
                            <td colspan="1" class="px-2 py-2 text-center">
                                <select type="text" name="categories" class="input-custom-1 py-1 my-1">
                                    <option value="all-games">100 %</option>
                                    <option value="all-games">50%</option>
                                </select>
                            </td>
                            <td colspan="1" class="px-2 py-2 text-center">
                                <select type="text" name="categories" class="input-custom-1 py-1 my-1">
                                    <option value="all-games">100 %</option>
                                    <option value="all-games">50%</option>
                                </select>
                            </td>
                            <td colspan="1" class="px-2 py-2 text-center">
                                <select type="text" name="categories" class="input-custom-1 py-1 my-1">
                                    <option value="all-games">Yes</option>
                                    <option value="all-games">No</option>
                                </select>
                            </td>
                        </tr>

                        <tr>
                            <td colspan="1" class="px-2 py-2 text-center">Cockfight</td>
                            <td colspan="1" class="px-2 py-2 text-center">
                                <select type="text" name="categories" class="input-custom-1 py-1 my-1">
                                    <option value="all-games">100 %</option>
                                    <option value="all-games">50%</option>
                                </select>       
                            </td>
                            <td colspan="1" class="px-2 py-2 text-center">
                                <select type="text" name="categories" class="input-custom-1 py-1 my-1">
                                    <option value="all-games">100 %</option>
                                    <option value="all-games">50%</option>
                                </select>
                            </td>
                            <td colspan="1" class="px-2 py-2 text-center">
                                <select type="text" name="categories" class="input-custom-1 py-1 my-1">
                                    <option value="all-games">100 %</option>
                                    <option value="all-games">50%</option>
                                </select>
                            </td>
                            <td colspan="1" class="px-2 py-2 text-center">
                                <select type="text" name="categories" class="input-custom-1 py-1 my-1">
                                    <option value="all-games">Yes</option>
                                    <option value="all-games">No</option>
                                </select>
                            </td>
                        </tr>

                        <tr>
                            <td colspan="1" class="px-2 py-2 text-center">P2P</td>
                            <td colspan="1" class="px-2 py-2 text-center">
                                <select type="text" name="categories" class="input-custom-1 py-1 my-1">
                                    <option value="all-games">100 %</option>
                                    <option value="all-games">50%</option>
                                </select>       
                            </td>
                            <td colspan="1" class="px-2 py-2 text-center">
                                <select type="text" name="categories" class="input-custom-1 py-1 my-1">
                                    <option value="all-games">100 %</option>
                                    <option value="all-games">50%</option>
                                </select>
                            </td>
                            <td colspan="1" class="px-2 py-2 text-center">
                                <select type="text" name="categories" class="input-custom-1 py-1 my-1">
                                    <option value="all-games">100 %</option>
                                    <option value="all-games">50%</option>
                                </select>
                            </td>
                            <td colspan="1" class="px-2 py-2 text-center">
                                <select type="text" name="categories" class="input-custom-1 py-1 my-1">
                                    <option value="all-games">Yes</option>
                                    <option value="all-games">No</option>
                                </select>
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>

            <div class="flex justify-center mt-4">
                <button mat-button class="bg-yellow-600 rounded-lg text-white w-72 py-1 mb-2 w-50">Create</button>
            </div>
        </div>
    </div><!--end container-->
</app-admin-comp-wrapper>
T