import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';
import { AdminCompWrapperComponent } from '../../components/admin-comp-wrapper/admin-comp-wrapper.component';
import { SimplebarAngularComponent, SimplebarAngularModule } from 'simplebar-angular';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-admin-pages-report-dailyreport',
  templateUrl: './admin-pages-report-dailyreport.component.html',
  styleUrl: './admin-pages-report-dailyreport.component.scss',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    AdminCompWrapperComponent,
    SimplebarAngularModule,
    MatIcon
  ]
})
export class AdminPagesReportDailyreportComponent {

dailyReportList = [
  {
    agentID : "LOGAA",
    username : "logaa",
    activeMember : 156,
    newRegAccPerDep : "62/54",
    totalNewDep : 5987907.00,
    totalTurnover : 134531855.00,
    totalWinlose : -13095991.00,
    totalDeposit : 62374051.00,
    totalWithdrawal: 29543246.00,
    totalAdjDep : 0.00,
    totalAdjWithdraw : 0.00,
    totalPromotion : 1563856.15,
    totalCashback : 0.00,
    totalCashRebate : 0.00,
    totalReffComm : 0.00,
    totalBonusCredit : 0.00
  }
]

}
