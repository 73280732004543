import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { AdminCompWrapperComponent } from '../../components/admin-comp-wrapper/admin-comp-wrapper.component';
import { SimplebarAngularModule } from 'simplebar-angular';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-admin-pages-report-dailytotalgamesreport',
  templateUrl: './admin-pages-report-dailytotalgamesreport.component.html',
  styleUrl: './admin-pages-report-dailytotalgamesreport.component.scss',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    AdminCompWrapperComponent,
    SimplebarAngularModule,
    MatIcon
  ]
})
export class AdminPagesReportDailytotalgamesreportComponent {
  dailyTotalGamesReports = [
    {
      settlementDate : "10-20-2024",
      brandURL : "www.sushi77.5g.in",
      agentID : "LOGAAAA",
      gameType : "SLOTS",
      currency : "IDR",
      betAmount : "13803400,00",
      creditAmount : "13169414,00",
      netAmount : "633986,00",
    },
    {
      settlementDate : "10-20-2024",
      brandURL : "www.sushi77.5g.in",
      agentID : "LOGAAAA",
      gameType : "FISH_HUNTER",
      currency : "IDR",
      betAmount : "9950000,00",
      creditAmount : "9449600,00",
      netAmount : "500400,00",
    },
    {
      settlementDate : "10-20-2024",
      brandURL : "www.sushi77.5g.in",
      agentID : "LOGAAAA",
      gameType : "LIVE_CASINO",
      currency : "IDR",
      betAmount : "167000,00",
      creditAmount : "123990,00",
      netAmount : "43010,00",
    },
    {
      settlementDate : "10-20-2024",
      brandURL : "www.si-rendi89.store",
      agentID : "LOGAAAD",
      gameType : "SLOTS",
      currency : "IDR",
      betAmount : "22375600,00",
      creditAmount : "20127940,00",
      netAmount : "2247660,00",
    },
    {
      settlementDate : "10-20-2024",
      brandURL : "www.jaguarwin69.today",
      agentID : "LOGAAAC",
      gameType : "SLOTS",
      currency : "IDR",
      betAmount : "66601675,00",
      creditAmount : "60056920,00",
      netAmount : "6544755,00",
    },
    {
      settlementDate : "10-20-2024",
      brandURL : "www.jaguarwin69.today",
      agentID : "LOGAAAC",
      gameType : "SPORTS",
      currency : "IDR",
      betAmount : "21634180,00",
      creditAmount : "18508000,00",
      netAmount : "3126180,00",
    },
  ]

}
