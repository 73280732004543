<div class="page-wrapper" [ngClass]="show ? 'toggled' : ''">
   
    <!-- sidebar-wrapper -->
    <app-admin-comp-sidebar/>
    <!-- sidebar-wrapper  -->

    <!-- Start Page Content -->
    <main class="page-content bg-gray-50 dark:bg-slate-800">
        <!-- Top Header -->
        <app-admin-comp-navbar (showChange)="updateShow($event)"/>
        <!-- Top Header -->

        <ng-content></ng-content>
        <!-- Footer Start -->
        <footer class="shadow dark:shadow-gray-700 bg-white dark:bg-slate-900 px-6 py-4">
            <div class="container-fluid">
                <div class="grid grid-cols-1">
                    <div class="sm:text-start text-center mx-md-2">
                        <p class="mb-0 text-slate-400">© {{year}} Test
                    </div><!--end col-->
                </div><!--end grid-->
            </div><!--end container-->
        </footer><!--end footer-->
        <!-- End -->
    </main>
    <!--End page-content" -->
</div>
