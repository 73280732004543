<nav id="sidebar" class="sidebar-wrapper">
    <div class="sidebar-content">
        <div class="sidebar-brand">
            <a [routerLink]="'/'">
                <img [src]="'assets/images/logo-dark.png'" height="24" class="block dark:hidden" alt="">
                <img [src]="'assets/images/logo-light.png'" height="24" class="hidden dark:block" alt="">
            </a>
        </div>
        <ngx-simplebar style="height: calc(100% - 70px);">

            <ul class="sidebar-menu border-t dark:border-white/10 border-gray-100" data-simplebar >
                <li [ngClass]="menu === '/admin/dashboard' ? 'active' : ''">
                    <a [routerLink]="'/admin/dashboard'"><i data-feather="airplay" class="h-4 w-4 me-3"></i>Dashboard</a>
                </li>

                <li [ngClass]="menu === '/admin/news' ? 'active' : ''">
                    <a [routerLink]="'/admin/news'"><i data-feather="info" class="h-4 w-4 me-3"></i>News</a>
                </li>

                <li class="sidebar-dropdown" [ngClass]="['/admin/total-bet/sbo-sport','/admin/total-bet/bti-sport','/admin/total-bet/ug-sport'].includes(menu) ? 'active' : ''">
                    <a href="#" (click)="openMenu($event, '/admin/total-bet')"><i data-feather="bar-chart-2" class="h-4 w-4 me-3"></i>Total Bet</a>
                    <div class="sidebar-submenu" [ngClass]="['/admin/total-bet/sbo-sport','/admin/total-bet/bti-sport','/admin/total-bet/ug-sport','/admin/total-bet'].includes(subManu || menu) ? 'block' : ''">
                        <ul>
                            <li [ngClass]="menu === '/admin/total-bet/sbo-sport' ? 'active' : ''"><a [routerLink]="'/admin/total-bet/sbo-sport'">SBO-Sport</a></li>
                            <li [ngClass]="menu === '/admin/total-bet/bti-sport' ? 'active' : ''"><a [routerLink]="'/admin/total-bet/bti-sport'">BTI-Sport</a></li>
                            <li [ngClass]="menu === '/admin/total-bet/ug-sport' ? 'active' : ''"><a [routerLink]="'/admin/total-bet/ug-sport'">UG-Sport</a></li>
                        </ul>
                    </div>
                </li>

                <li class="sidebar-dropdown" [ngClass]="['/admin/member-manangement/new-ma','/admin/member-manangement/account-list','/admin/member-manangement/statement-log','/admin/member-manangement/betting','/admin/member-manangement/commission','/admin/member-manangement/preset-pt','/admin/member-manangement/effective-pt'].includes(menu) ? 'active' : ''">
                    <a href="#" (click)="openMenu($event, '/admin/member-manangement')"><i data-feather="user" class="h-4 w-4 me-3"></i>Member Management</a>
                    <div class="sidebar-submenu" [ngClass]="['/admin/member-manangement/new-ma','/admin/member-manangement/account_list','/admin/member-manangement/statement-log','/admin/member-manangement/betting','/admin/member-manangement/commission','/admin/member-manangement/preset-pt','/admin/member-manangement/effective-pt','/admin/member-manangement'].includes(subManu || menu) ? 'block' : ''">
                        <ul>
                            <li [ngClass]="menu === '/admin/member-manangement/new-ma' ? 'active' : ''"><a [routerLink]="'/admin/member-manangement/new-ma'">New MA</a></li>
                            <li [ngClass]="menu === '/admin/member-manangement/account-list' ? 'active' : ''"><a [routerLink]="'/admin/member-manangement/account-list'">Account List</a></li>
                            <li [ngClass]="menu === '/admin/member-manangement/statement-log' ? 'active' : ''"><a [routerLink]="'/admin/member-manangement/statement-log'">Statement Log</a></li>
                            <li [ngClass]="menu === '/admin/member-manangement/betting' ? 'active' : ''"><a [routerLink]="'/admin/member-manangement/betting'">Betting</a></li>
                            <li [ngClass]="menu === '/admin/member-manangement/commission' ? 'active' : ''"><a [routerLink]="'/admin/member-manangement/commission'">Commission</a></li>
                            <li [ngClass]="menu === '/admin/member-manangement/preset-pt' ? 'active' : ''"><a [routerLink]="'/admin/member-manangement/preset-pt'">Preset PT</a></li>
                            <li [ngClass]="menu === '/admin/member-manangement/effective-pt' ? 'active' : ''"><a [routerLink]="'/admin/member-manangement/effective-pt'">Effective PT</a></li>
                        </ul>
                    </div>
                </li>

                <li class="sidebar-dropdown" [ngClass]="['/admin/report/winlose-report','/admin/report/winlose-report-by-game','/admin/report/statement','/admin/report/daily-report','/admin/report/running-report','/admin/report/daily-total-games-report'].includes(menu) ? 'active' : ''">
                    <a href="#" (click)="openMenu($event, '/admin/report')"><i data-feather="trello" class="h-4 w-4 me-3"></i>Report</a>
                    <div class="sidebar-submenu" [ngClass]="['/admin/report/winlose-report','/admin/report/winlose-report-by-game','/admin/report/statement','/admin/report/daily-report','/admin/report/running-report','/admin/report/daily-total-games-report','/admin/report'].includes(subManu || menu) ? 'block' : ''">
                        <ul>
                            <li [ngClass]="menu === '/admin/report/winlose-report' ? 'active' : ''"><a [routerLink]="'/admin/report/winlose-report'">Win/Lose Report</a></li>
                            <li [ngClass]="menu === '/admin/report/winlose-report-by-game' ? 'active' : ''"><a [routerLink]="'/admin/report/winlose-report-by-game'">Win/Lose Report by Game</a></li>
                            <li [ngClass]="menu === '/admin/report/statement' ? 'active' : ''"><a [routerLink]="'/admin/report/statement'">Statement</a></li>
                            <li [ngClass]="menu === '/admin/report/daily-report' ? 'active' : ''"><a [routerLink]="'/admin/report/daily-report'">Daily Report</a></li>
                            <li [ngClass]="menu === '/admin/report/running-report' ? 'active' : ''"><a [routerLink]="'/admin/report/running-report'">Running Report</a></li>
                            <li [ngClass]="menu === '/admin/report/daily-total-games-report' ? 'active' : ''"><a [routerLink]="'/admin/report/daily-total-games-report'">Daily Total Games Report</a></li>
                        </ul>
                    </div>
                </li>

                <li class="sidebar-dropdown" [ngClass]="['admin/transfer/credit-transfer'].includes(menu) ? 'active' : ''">
                    <a href="#" (click)="openMenu($event, '/admin/transfer')"><i data-feather="dollar-sign" class="h-4 w-4 me-3"></i>Transfer</a>
                    <div class="sidebar-submenu" [ngClass]="['/admin/transfer','admin/transfer/credit-transfer'].includes(subManu || menu) ? 'block' : ''">
                        <ul>
                            <li [ngClass]="menu === 'admin/transfer/credit-transfer' ? 'active' : ''"><a [routerLink]="'admin/transfer/credit-transfer'">Credit Transfer</a></li>
                        </ul>
                    </div>
                </li>

                <li class="sidebar-dropdown" [ngClass]="['/admin/tools/ip-whitelist','/admin/tools/game-settings','/admin/tools/member-ip-track','/admin/tools/master-ip-whitelist'].includes(menu) ? 'active' : ''">
                    <a href="#" (click)="openMenu($event, '/admin/tools')"><i data-feather="tool" class="h-4 w-4 me-3"></i>Tools</a>
                    <div class="sidebar-submenu" [ngClass]="['/admin/tools','/admin/tools/ip-whitelist','/admin/tools/game-settings','/admin/tools/member-ip-track','/admin/tools/master-ip-whitelist'].includes(subManu || menu) ? 'block' : ''">
                        <ul>
                            <li [ngClass]="menu === '/admin/tools/ip-whitelist' ? 'active' : ''"><a [routerLink]="'/admin/tools/ip-whitelist'">IP Whitelist</a></li>
                            <li [ngClass]="menu === '/admin/tools/game-settings' ? 'active' : ''"><a [routerLink]="'/admin/tools/game-settings'">Game Settings</a></li>
                            <li [ngClass]="menu === '/admin/tools/member-ip-track' ? 'active' : ''"><a [routerLink]="'/admin/tools/member-ip-track'">Member IP Track</a></li>
                            <li [ngClass]="menu === '/admin/tools/master-ip-whitelist' ? 'active' : ''"><a [routerLink]="'/admin/tools/master-ip-whitelist'">Master IP Whitelist</a></li>
                        </ul>
                    </div>
                </li>

 
            </ul>
            <!-- sidebar-menu  -->
        </ngx-simplebar>
    </div>
</nav>