<app-admin-comp-wrapper>
    <div class="container-fluid relative px-3">
        <div class="layout-specing">
            <!-- Start Content -->
            <div class="md:flex justify-between items-center">
                <div>
                    <h5 class="font-medium text-base text-slate-400">Report</h5>
                    <h5 class="font-semibold text-2xl mb-1">Win/Lose Report by Games</h5>
                </div>
                <!-- <span class="text-slate-400">My balance: <span class="font-semibold text-emerald-600">$ 45,578.032</span></span> -->
            </div>

            <div class="bg-slate-900 rounded-lg p-3 flex flex-col md:flex-row gap-2 w-full items-center mt-5">
                <div class="flex gap-2 md:w-[29rem] w-full items-center">
                    <div class="w-full">
                        <label for="date-start">Date Start</label>
                        <input type="date" name="date-start" class="input-custom-1 w-full"
                        placeholder="Masukan kata kunci">
                    </div>
                    <div class="w-full">
                        <label for="date-end">Date End</label>
                        <input type="date" name="date-end" class="input-custom-1 w-full"
                        placeholder="Masukan kata kunci">
                    </div>
                </div>
                <div class="flex gap-2 md:w-[29rem] w-full items-center">
                    <div class="w-full">
                        <label for="categories">Categories</label>
                        <select type="text" name="categories" class="input-custom-1 w-full">
                            <option value="all-games">All Games</option>
                            <option value="all-games">Cockfight</option>
                            <option value="all-games">Fishing</option>
                            <option value="all-games">HKB Live</option>
                            <option value="all-games">HKB Poker</option>
                            <option value="all-games">Live Casino</option>
                            <option value="all-games">Live Casino (SBO)</option>
                            <option value="all-games">Lottery (Number)</option>
                            <option value="all-games">Lottery (Togel)</option>
                            <option value="all-games">Lottery 2 (Togel)</option>
                            <option value="all-games">Lottery (Special 4D)</option>
                            <option value="all-games">P2P (1GPOKER)</option>
                            <option value="all-games">RNG</option>
                            <option value="all-games">Slot</option>
                            <option value="all-games">Sport</option>
                            <option value="all-games">Promo (PP)</option>
                        </select>
                    </div>

                </div>
      
                <button mat-button class="bg-yellow-600 rounded-lg text-white md:w-72 py-6 w-full">Search</button>
            </div>

            <div class="pt-7 flex flex-row">
                <p>Show Amount
                    <select type="text" name="entries" class="input-custom-1 w-full bg-slate-900">
                        <option value="50">50 Entries</option>
                        <option value="100">100 Entries</option>
                    </select>
                </p>
                <button mat-button class="bg-slate-900 rounded-lg text-white px-2 py-1 mx-1">Copy</button>
                <button mat-button class="bg-slate-900 rounded-lg text-white px-2 py-1 mx-1">CSV</button>
                <button mat-button class="bg-slate-900 rounded-lg text-white px-2 py-1 mx-1">Excel</button>
                <button mat-button class="bg-slate-900 rounded-lg text-white px-2 py-1 mx-1">PDF</button>
                <button mat-button class="bg-slate-900 rounded-lg text-white px-2 py-1 mx-1">Print</button>
            </div>

            <!-- Table Dream -->
            <div class="overflow-x-auto mt-3 rounded-lg bg-gray-700">
                <table class="table-striped w-full text-xs text-left text-gray-700 border-none">
                    <thead class="bg-slate-900 text-gray-200">
                        <tr>
                            <th rowspan="2" scope="col" class="px-6 py-2 text-center">Game Provider</th>
                            <th rowspan="2" scope="col" class="px-6 py-2 text-center">Total Active Member</th>
                            <th rowspan="2" scope="col" class="px-6 py-2 text-center">Turn Over</th>
                            <th colspan="3" scope="col" class="px-6 pt-3 text-center">Member</th>
                            <th colspan="4" scope="col" class="px-6 pt-3 text-center">Agent</th>
                            <th colspan="4" scope="col" class="px-6 pt-3 text-center">M Agent</th>
                            <th colspan="4" scope="col" class="px-6 pt-3 text-center">SM Agent</th>
                            <th rowspan="2" scope="col" class="px-6 py-2 text-center">Prog JP</th>
                            <th rowspan="2" scope="col" class="px-6 py-2 text-center">Company Total</th>
                        </tr>
                        <tr>
                            <th scope="col" class="px-6 pb-3 text-center">Win</th>
                            <th scope="col" class="px-6 pb-3 text-center">Commision</th>
                            <th scope="col" class="px-6 pb-3 text-center">Total</th>
                            <th scope="col" class="px-6 pb-3 text-center">Win</th>
                            <th scope="col" class="px-6 pb-3 text-center">Commision</th>
                            <th scope="col" class="px-6 pb-3 text-center">Prog JP</th>
                            <th scope="col" class="px-6 pb-3 text-center">Total</th>
                            <th scope="col" class="px-6 pb-3 text-center">Win</th>
                            <th scope="col" class="px-6 pb-3 text-center">Commision</th>
                            <th scope="col" class="px-6 pb-3 text-center">Prog JP</th>
                            <th scope="col" class="px-6 pb-3 text-center">Total</th>
                            <th scope="col" class="px-6 pb-3 text-center">Win</th>
                            <th scope="col" class="px-6 pb-3 text-center">Commision</th>
                            <th scope="col" class="px-6 pb-3 text-center">Prog JP</th>
                            <th scope="col" class="px-6 pb-3 text-center">Total</th>
                        </tr>
                    </thead>
                    <tbody class="bg-slate-800">
                        @for (winLoseByGame of winLoseByGames; track winLoseByGame.gameProvider) {
                            <tr>
                                <td class="px-2 py-2 text-center">{{winLoseByGame.gameProvider}}</td>
                                <td class="px-2 py-2 text-center">{{winLoseByGame.totalActiveMembers}}</td>
                                <td class="px-2 py-2 text-center">{{winLoseByGame.turnOver}}</td>
                                <td class="px-2 py-2 text-center">{{winLoseByGame.member.win}}</td>
                                <td class="px-2 py-2 text-center">{{winLoseByGame.member.comm}}</td>
                                <td class="px-2 py-2 text-center">{{winLoseByGame.member.total}}</td>
                                <td class="px-2 py-2 text-center">{{winLoseByGame.agent.win}}</td>
                                <td class="px-2 py-2 text-center">{{winLoseByGame.agent.comm}}</td>
                                <td class="px-2 py-2 text-center">{{winLoseByGame.agent.progJP}}</td>
                                <td class="px-2 py-2 text-center">{{winLoseByGame.agent.total}}</td>
                                <td class="px-2 py-2 text-center">{{winLoseByGame.mAgent.win}}</td>
                                <td class="px-2 py-2 text-center">{{winLoseByGame.mAgent.comm}}</td>
                                <td class="px-2 py-2 text-center">{{winLoseByGame.mAgent.progJP}}</td>
                                <td class="px-2 py-2 text-center">{{winLoseByGame.mAgent.total}}</td>
                                <td class="px-2 py-2 text-center">{{winLoseByGame.SMAgent.win}}</td>
                                <td class="px-2 py-2 text-center">{{winLoseByGame.SMAgent.comm}}</td>
                                <td class="px-2 py-2 text-center">{{winLoseByGame.SMAgent.progJP}}</td>
                                <td class="px-2 py-2 text-center">{{winLoseByGame.SMAgent.total}}</td>
                                <td class="px-2 py-2 text-center">{{winLoseByGame.progJP}}</td>
                                <td class="px-2 py-2 text-center">{{winLoseByGame.companyTotal}}</td>

                            </tr>
                        }
                       
                    </tbody>
                </table>
            </div>
            <!-- End Content -->
        </div>
    </div><!--end container-->
</app-admin-comp-wrapper>
