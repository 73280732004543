<app-admin-comp-wrapper>
    <div class="container-fluid relative px-3">
        <div class="layout-specing">
            <!-- Start Content -->
            <div class="md:flex justify-between items-center">
                <div>
                    <h5 class="font-medium text-base text-slate-400">Member Management</h5>
                    <h5 class="font-semibold text-2xl mb-1">Account List</h5>
                </div>
                <!-- <span class="text-slate-400">My balance: <span class="font-semibold text-emerald-600">$ 45,578.032</span></span> -->
            </div>

            <div class="bg-slate-900 rounded-lg p-3 flex flex-col min-[1750px]:flex-row gap-2 w-full items-center mt-5">
                <div class="flex gap-2 w-full items-center">
                    <div class="mx-1">
                        <label for="agent-id">Search Criteria</label>
                        <select type="text" name="search-criteria" class="input-custom-1 w-full">
                            <option value="member-listing">Member Listing</option>
                        </select>
                    </div>
                    <div class="mx-1">
                        <label for="status">Status</label>
                        <select type="text" name="status" class="input-custom-1 w-full">
                            <option value="member">Member</option>
                            <option value="new-register">New Register</option>
                        </select>
                    </div>

                </div>
                <div class="flex gap-2 w-full items-center">
                    <div class="mx-1">
                        <label for="date-start">Date Start</label>
                        <input type="date" name="date-start" class="input-custom-1 w-full"
                        placeholder="Masukan kata kunci">
                    </div>
                    <div class="mx-1">
                        <label for="date-end">Date End</label>
                        <input type="date" name="date-end" class="input-custom-1 w-full"
                        placeholder="Masukan kata kunci">
                    </div>
                </div>
                <div class="flex gap-2 w-full items-center">
                    <div class="mx-1">
                        <label for="rows">Sort By</label>
                        <select type="text" name="rows" class="input-custom-1 w-full">
                            <option value="50">50 Rows</option>
                        </select>
                    </div>
                    <div class="mx-1">
                        <label for="sort-by">Sort By</label>
                        <select type="text" name="sort-by" class="input-custom-1 w-full">
                            <option value="agent-id">Agent ID</option>
                            <option value="username">Username</option>
                            <option value="own-ref-id">Own Ref ID</option>
                            <option value="upline-ref-id">Upline Ref ID</option>
                            <option value="status">Status</option>
                            <option value="suspend">Suspend</option>
                            <option value="mailing-subscription">Mailing Subscription</option>
                            <option value="bank-refund-method">Bank Refund Method</option>
                            <option value="balance">Balance</option>
                            <option value="created-date">Created Date</option>
                            <option value="first-deposit-date">First Depostit Date</option>
                            <option value="last-deposit-date">Last Deposit Date</option>
                            <option value="last-login-time">Last Login Time</option>
                            <option value="last-login-ip">Last Login IP</option>
                        </select>
                    </div>
                    <div class="mx-1">
                        <label for="arrangement">Arrangement</label>
                        <select type="text" name="arrangement" class="input-custom-1 w-full">
                            <option value="asc">Ascending</option>
                            <option value="desc">Descending</option>
                        </select>
                    </div>

                </div>
                <div class="flex flex-col mt-3">
                    <button mat-button class="bg-yellow-600 rounded-lg text-white w-72 py-1 mb-2 w-50">Search</button>
                    <button mat-button class="bg-yellow-600 rounded-lg text-white w-72 py-1 w-50">List All</button>
                </div>
            </div>

            <!-- <div class="pt-7">
                <button mat-button class="bg-slate-900 rounded-lg text-white px-2 py-1 mx-1">Copy</button>
                <button mat-button class="bg-slate-900 rounded-lg text-white px-2 py-1 mx-1">CSV</button>
                <button mat-button class="bg-slate-900 rounded-lg text-white px-2 py-1 mx-1">Excel</button>
                <button mat-button class="bg-slate-900 rounded-lg text-white px-2 py-1 mx-1">PDF</button>
                <button mat-button class="bg-slate-900 rounded-lg text-white px-2 py-1 mx-1">Print</button>
            </div> -->

            <!-- Table Dream -->
            <div class="overflow-x-auto mt-3 rounded-lg bg-gray-700">
                <table class="table-striped w-full text-xs text-left text-gray-700 border-none">
                    <thead class="bg-slate-900 text-gray-200">
                        <tr>
                            <th scope="col" class="px-6 py-3 text-center">Agent ID</th>
                            <th scope="col" class="px-6 py-3 text-center">Username</th>
                            <th scope="col" class="px-6 py-3 text-center">Own Ref ID</th>
                            <th scope="col" class="px-6 py-3 text-center">Upline Ref ID</th>
                            <th scope="col" class="px-6 py-3 text-center">Status</th>
                            <th scope="col" class="px-6 py-3 text-center">Suspend</th>
                            <th scope="col" class="px-6 py-3 text-center">Mailing Subscription</th>
                            <th scope="col" class="px-6 py-3 text-center">Bank / Refund Method</th>
                            <th scope="col" class="px-6 py-3 text-center">Balance</th>
                            <th scope="col" class="px-6 py-3 text-center">Created Date</th>
                            <th scope="col" class="px-6 py-3 text-center">First Deposit Date</th>
                            <th scope="col" class="px-6 py-3 text-center">Last Deposit Date</th>
                            <th scope="col" class="px-6 py-3 text-center">Last Login Date</th>
                            <th scope="col" class="px-6 py-3 text-center">Last Login IP</th>
                            <th scope="col" class="px-6 py-3 text-center">Action</th>

                        </tr>
                    </thead>
                    <tbody class="bg-slate-800">
                        @for (account of accounts; track account.agentID) {
                            <tr>
                                <td class="px-2 py-2 text-center">{{account.agentID}}</td>
                                <td class="px-2 py-2 text-center">{{account.username}}</td>
                                <td class="px-2 py-2 text-center">{{account.ownRefId}}</td>
                                <td class="px-2 py-2 text-center">{{account.uplineRefId}}</td>
                                <td class="px-2 py-2 text-center">{{account.status}}</td>
                                <td class="px-2 py-2 text-center">{{account.suspend}}</td>
                                <td class="px-2 py-2 text-center">{{account.mailingSubscription}}</td>
                                <td class="px-2 py-2 text-center">{{account.bankRefundMethod}}</td>
                                <td class="px-2 py-2 text-center">{{account.balance}}</td>
                                <td class="px-2 py-2 text-center">{{account.createdDate}}</td>
                                <td class="px-2 py-2 text-center">{{account.firstDepositDate}}</td>
                                <td class="px-2 py-2 text-center">{{account.lastDepositDate}}</td>
                                <td class="px-2 py-2 text-center">{{account.lastLoginDate}}</td>
                                <td class="px-2 py-2 text-center">{{account.lastLoginIP}}</td>
                                <td class="px-2 py-2 text-center">
                                    <button mat-button class="bg-yellow-600 rounded-lg text-white px-2">Edit</button>
                                </td>
                            </tr>
                        }
                       
                    </tbody>
                </table>
            </div>
            <!-- End Content -->
        </div>
    </div><!--end container-->
</app-admin-comp-wrapper>
