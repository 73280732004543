<app-admin-comp-wrapper>
    <div class="container-fluid relative px-3">
        <div class="layout-specing">
            <!-- Start Content -->
            <div class="md:flex justify-between items-center">
                <div>
                    <h5 class="font-medium text-base text-slate-400">Report</h5>
                    <h5 class="font-semibold text-2xl mb-1">Statement</h5>
                </div>
                <!-- <span class="text-slate-400">My balance: <span class="font-semibold text-emerald-600">$ 45,578.032</span></span> -->
            </div>

            <div class="bg-slate-900 rounded-lg p-3 flex flex-col md:flex-row gap-2 w-full items-center mt-5">
                <div class="flex gap-2 md:w-[29rem] w-full items-center">
                    <div class="w-full">
                        <label for="date-start">Date Start</label>
                        <input type="date" name="date-start" class="input-custom-1 w-full"
                        placeholder="Masukan kata kunci">
                    </div>
                    <div class="w-full">
                        <label for="date-end">Date End</label>
                        <input type="date" name="date-end" class="input-custom-1 w-full"
                        placeholder="Masukan kata kunci">
                    </div>
                </div>

      
                <button mat-button class="bg-yellow-600 rounded-lg text-white md:w-72 py-6 w-full">Search</button>
            </div>

            <div class="pt-7 flex flex-row">
                <p class="mx-1">Show Amount
                    <select type="text" name="entries" class="input-custom-1 w-full bg-slate-900">
                        <option value="10">10 Entries</option>
                        <option value="50">50 Entries</option>
                        <option value="100">100 Entries</option>
                    </select>
                </p>
                <p class="mx-3">Search
                    <input type="text" name="agent-id" class="bg-slate-900 input-custom-1 w-full"
                    placeholder="">
                </p>
            </div>

            <!-- Table Dream -->
            <div class="overflow-x-auto mt-3 rounded-lg bg-gray-700">
                <table class="table-striped w-full text-xs text-left text-gray-700 border-none">
                    <thead class="bg-slate-900 text-gray-200">
                        <tr>
                            <th scope="col" class="px-6 py-3 text-center">#</th>
                            <th scope="col" class="px-6 py-3 text-center">Currency</th>
                            <th scope="col" class="px-6 py-3 text-center">Date</th>
                            <th scope="col" class="px-6 py-3 text-center">Type</th>
                            <th scope="col" class="px-6 py-3 text-center">Product</th>
                            <th scope="col" class="px-6 py-3 text-center">Betting</th>
                            <th scope="col" class="px-6 py-3 text-center">Commission</th>
                            <th scope="col" class="px-6 py-3 text-center">Credit Balance</th>


                        </tr>
                    </thead>
                    <tbody class="bg-slate-800">
 
                    </tbody>
                </table>
            </div>
            <!-- End Content -->
        </div>
    </div><!--end container-->
</app-admin-comp-wrapper>
