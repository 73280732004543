<app-admin-comp-wrapper>
    <div class="container-fluid relative px-3">
        <div class="layout-specing">
            <!-- Start Content -->
            <div class="md:flex justify-between items-center">
                <div>
                    <h5 class="font-medium text-base text-slate-400">Member Management</h5>
                    <h5 class="font-semibold text-2xl mb-1">Statement Log</h5>
                </div>
                <!-- <span class="text-slate-400">My balance: <span class="font-semibold text-emerald-600">$ 45,578.032</span></span> -->
            </div>

            <div class="bg-slate-900 rounded-lg p-3 flex flex-col md:flex-row gap-2 w-full items-center mt-5">
                <div class="flex gap-2 md:w-[29rem] w-full items-center">
                    <div class="w-full">
                        <label for="agent-id">Agent ID</label>
                        <input type="text" name="agent-id" class="input-custom-1 w-full"
                        placeholder="">
                    </div>
                    <div class="w-full">
                        <label for="date">Date</label>
                        <input type="date" name="date" class="input-custom-1 w-full"
                        placeholder="Masukan kata kunci">
                    </div>
                </div>
                <div class="flex gap-2 md:w-[29rem] w-full items-center">
                    <div class="w-full">
                        <label for="ticket-id">Ticket ID</label>
                        <input type="text" name="ticket-id" class="input-custom-1 w-full"
                        placeholder="">
                    </div>
                    <div class="w-full">
                        <label for="rows">Rows</label>
                        <input type="number" name="rows" class="input-custom-1 w-full"
                        placeholder="">
                    </div>
                </div>
      
                <button mat-button class="bg-yellow-600 rounded-lg text-white md:w-72 py-6 w-full">Search</button>
            </div>

            <div class="pt-7">
                <button mat-button class="bg-slate-900 rounded-lg text-white px-2 py-1 mx-1">Copy</button>
                <button mat-button class="bg-slate-900 rounded-lg text-white px-2 py-1 mx-1">CSV</button>
                <button mat-button class="bg-slate-900 rounded-lg text-white px-2 py-1 mx-1">Excel</button>
                <button mat-button class="bg-slate-900 rounded-lg text-white px-2 py-1 mx-1">PDF</button>
                <button mat-button class="bg-slate-900 rounded-lg text-white px-2 py-1 mx-1">Print</button>
            </div>

            <!-- Table Dream -->
            <div class="overflow-x-auto mt-3 rounded-lg bg-gray-700">
                <table class="table-striped w-full text-xs text-left text-gray-700 border-none">
                    <thead class="bg-slate-900 text-gray-200">
                        <tr>
                            <th scope="col" class="px-6 py-3 text-center">Wallet Type</th>
                            <th scope="col" class="px-6 py-3 text-center">Source</th>
                            <th scope="col" class="px-6 py-3 text-center">Track ID / Ticket ID</th>
                            <th scope="col" class="px-6 py-3 text-center">Amount</th>
                            <th scope="col" class="px-6 py-3 text-center">Balance Before</th>
                            <th scope="col" class="px-6 py-3 text-center">Balance After</th>
                            <th scope="col" class="px-6 py-3 text-center">Date Time</th>
                        </tr>
                    </thead>
                    <tbody class="bg-slate-800">
                        @for (statementLog of statementLogs; track statementLog.walletType) {
                            <tr>
                                <td class="px-2 py-2 text-center">{{statementLog.walletType}}</td>
                                <td class="px-2 py-2 text-center">{{statementLog.source}}</td>
                                <td class="px-2 py-2 text-center">{{statementLog.trackID}}</td>
                                <td class="px-2 py-2 text-center">{{statementLog.amount}}</td>
                                <td class="px-2 py-2 text-center">{{statementLog.balanceBefore}}</td>
                                <td class="px-2 py-2 text-center">{{statementLog.balanceAfter}}</td>
                                <td class="px-2 py-2 text-center">{{statementLog.dateTime}}</td>
                            </tr>
                        }
                       
                    </tbody>
                </table>
            </div>
            <!-- End Content -->
        </div>
    </div><!--end container-->
</app-admin-comp-wrapper>
