import { Component } from '@angular/core';
import { AdminCompWrapperComponent } from '../../components/admin-comp-wrapper/admin-comp-wrapper.component';
import { SimplebarAngularComponent, SimplebarAngularModule } from 'simplebar-angular';
import { MatIcon } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';


@Component({
  selector: 'app-admin-pages-membermanage-commision',
  templateUrl: './admin-pages-membermanage-commision.component.html',
  styleUrl: './admin-pages-membermanage-commision.component.scss',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    AdminCompWrapperComponent,
    SimplebarAngularModule,
    MatIcon
  ]
})
export class AdminPagesMembermanageCommisionComponent {

  commissionData = [
    {
      agentId : "LOGAA",
      accountName : "ONIX9 MA",
      commGroupA : 2.45,
      commGroupB : 1.225,
      lastLoginTime : "-"
    },
    {
      agentId : "LOGBB",
      accountName : "ONIX9 MA",
      commGroupA : 2.45,
      commGroupB : 1.225,
      lastLoginTime : "-"
    },
  ]
}
