<app-admin-comp-wrapper>
    <div class="container-fluid relative px-3">
        <div class="layout-specing">
            <!-- Start Content -->
            <div class="md:flex justify-between items-center">
                <div>
                    <h5 class="font-medium text-base text-slate-400">Tools</h5>
                    <h5 class="font-semibold text-2xl mb-1">Master IP Whitelist</h5>
                </div>
                <!-- <span class="text-slate-400">My balance: <span class="font-semibold text-emerald-600">$ 45,578.032</span></span> -->
            </div>

            <div class="bg-slate-900 rounded-lg p-3 flex flex-col min-[1750px]:flex-row gap-2 w-full items-center mt-5">


                <div class="flex gap-2 w-full items-center">
                    <div class="mx-1">
                        <label for="rows">Master Agent Active Toggle</label>
                        <select type="text" name="rows" class="input-custom-1 w-full">
                            <option value="50">Master Agents</option>
                            <option value="50">Agents</option>
                        </select>
                    </div>


                </div>
                <div class="flex flex-col mt-3">
                    <button mat-button class="bg-yellow-600 rounded-lg text-white w-72 py-1 mb-2 w-50">Search</button>
                </div>
            </div>
            <div class="overflow-x-auto mt-3 rounded-lg bg-gray-700">
                <table class="table-striped w-full text-xs text-left text-gray-700 border-none">
                    <thead class="bg-slate-900 text-gray-200">
                        <tr>
                            <th scope="col" class="px-6 py-3 text-center">Agent ID</th>
                            <th scope="col" class="px-6 py-3 text-center">Username</th>
                            <th scope="col" class="px-6 py-3 text-center">IP Whitelist</th>
                            <th scope="col" class="px-6 py-3 text-center">Add IP</th>

                        </tr>
                    </thead>
                    <tbody class="bg-slate-800">
                        @for (masterIP of masterIPList; track masterIP.agentID) {
                        <tr>
                            <td class="px-2 py-2 text-center">{{masterIP.agentID}}</td>
                            <td class="px-2 py-2 text-center">{{masterIP.username}}</td>
                            <td class="px-2 py-2 text-center">{{masterIP.ipWhitelistList}}</td>
                            <td class="px-2 py-2 text-center">
                                <input type="text" name="agent-id" class="input-custom-1" placeholder="">
                                <button mat-button class="bg-yellow-600 rounded-lg text-white p-3 mx-2">Add</button>

                            </td>

                        </tr>
                        }

                    </tbody>
                </table>
            </div>



        </div>
    </div><!--end container-->
</app-admin-comp-wrapper>